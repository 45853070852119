<template>
  <div class="content-wrap">
    <img src="../assets/home-banner.png" />
    <img src="../assets/home-content.png" alt="" />
    <!-- <div class="center-wrap" style="margin-top:40px;margin-bottom:26px;">
      <div class="center-1">
          <img src="../assets/newa.png">
          <div style="flex:1;padding-right:24px;">
            <div style="border-bottom: 1px solid #F2F2F2;">
              <div class="nav-title">新闻动态</div>
              <div class="title">
                <div class="text">大连钰佳机械科技有限公司欢迎广大客户</div>
                <div class="date">07-27</div>
              </div>
              <div class="desc">大连佳钰机械科技有限公司拥有先进的设备、技术的精湛与雄厚的实力，在坚持以卓越的品质、真诚的服务为基本的同时，专业执着、精益求精，努力获取客户的青昧。</div>
              <div class="title"></div>
            </div>
           <div style="padding-top:23px;">
              <div class="title" style="margin:0">
                <div class="text">可根据用户需求 定制各种电子看板</div>
                <div class="date">03-04</div>
              </div>
          
              <div class="title" style="margin-top:13px">
                <div class="text">大连佳钰机械科技有限公司更名通知</div>
                <div class="date">03-15</div>
              </div>
           </div>
          </div>
      </div>
      <div class="product">
        <div class="title">产品展示</div>
        <div class="title">Products</div>
        <div class="name">>起亚</div>
        <div class="name">>瑞隆</div>
        <div class="name">>丰富</div>
        <div class="name">>恒祥</div>
        <div class="name">>篮兰</div>
        <div class="name">>洁丽雅</div>
      </div>
    </div>
    <div class="center-wrap" style="justify-content: space-between;margin-bottom: 60px;background:unset;">
      <div class="card-wrap" style="background: #41AF6E;">
        <img src="../assets/join-company.png">
        <div class="card-right">
          <div class="title">走进佳钰</div>
          <div class="desc">卓越的品质、新颖时尚的设计，本质为本、专业执着、精益求精。</div>
        </div>
      </div>
      <div class="card-wrap" style="background: #B9383E;">
        <img src="../assets/case.png">
        <div class="card-right">
          <div class="title">经典案例</div>
          <div class="desc">专业客户服务，先进技术支援，您的满意是我们恒久不变的追求。</div>
        </div>
      </div>
      <div class="card-wrap" style="background: #BD9E70;">
        <img src="../assets/concat.png">
        <div class="card-right">
          <div class="title">联系我们</div>
          <div class="desc">辽宁 大连市 大连保税区泰华大厦 电话：86-0411-62778581</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "HomeContent",
};
</script>

<style scoped>
.content-wrap > img {
  width: 100%;
  /* height: 650px; */
}
.center-wrap {
  width: 1200px;
  margin: auto;
  background: white;
  display: flex;
}
.center-1 {
  display: flex;
  align-items: center;
  width: 826px;
  height: 311px;
}
.center-1 > img {
  width: 300px;
  height: 205px;
  margin-right: 26px;
}
.nav-title {
  font-size: 24px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #d80f20;
  margin-bottom: 27px;
}
.title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #333333;
  margin-bottom: 16px;
}
.title > .text {
  flex: 1;
}
.title > .date {
  font-size: 15px;
  font-family: DIN;
  font-weight: 500;
  color: #999999;
}
.desc {
  font-size: 15px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #999999;
  line-height: 26px;
  text-align: justify;
}

.product {
  background-image: url("../assets/product-show.png");
  width: 373px;
  height: 311px;
  background-position: 100%;
  padding-left: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}
.product > .name {
  line-height: 26px;
  font-size: 13px;
  color: white;
}
.product > .title {
  font-size: 24px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 13px;
}

.card-wrap {
  display: flex;
  width: 384px;
  height: 138px;
}
.card-wrap > img {
  width: 130px;
  height: 138px;
}
.card-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
}
.card-right > .title {
  font-size: 24px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  margin-bottom: 17px;
  color: #ffffff;
}
.card-right > .desc {
  font-size: 13px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #ffffff;
}
</style>
